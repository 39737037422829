//@flow
import React from "react";

type TUploaderContext = {
  onDrop: (acceptedFiles: []) => void,
};

const UploaderContext = React.createContext<TUploaderContext>({});

export default UploaderContext;
