//@flow
import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";

import UploaderContext from "./context";

export default function DropZone() {
  const { onDrop } = useContext(UploaderContext);

  const dropzone = useDropzone({
    onDrop,
    accept: [
      "image/png",
      "image/jpeg",
      "image/gif",
      "audio/*",
      "video/*",
      "*.mp4",
      "application/pdf",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  });

  return (
    <div {...dropzone.getRootProps()}>
      <input {...dropzone.getInputProps()} />
      <p className="lead">
        {dropzone.isDragActive
          ? "Drop it !!"
          : "Click me or drag a file to upload!"}
      </p>
    </div>
  );
}
