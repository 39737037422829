/*@flow */
import { gql, useMutation } from "@apollo/client";
import * as React from "react";
import { useLocation } from "react-router-dom";

import UploaderContext from "./context";
import DropZone from "./DropZone";
import LoadingIndicator from "./LoadingIndicator";

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
      url
    }
  }
`;

const CREATE_MESSAGES = gql`
  mutation CreateMessage($input: MessageInput!) {
    createMessage(input: $input) {
      scid
    }
  }
`;

const CLIENT_MESSAGE = gql`
  mutation ClientMessage($input: ClientMessageInput!) {
    clientMessage(input: $input) {
      success
    }
  }
`;

const findChild = (children, type) => {
  return React.Children.toArray(children).find((child) => child.type === type);
};

type TUploader = {
  LoadingIndicator?: React.Node,
  DropZone?: React.Node,
};

function uploaderReducer(state, action) {
  switch (action.type) {
    case "uploading": {
      return {
        ...state,
        error: null,
        message: null,
        uploading: true,
      };
    }
    case "done": {
      return {
        ...state,
        uploading: false,
        error: null,
        message: action.message,
      };
    }
    case "error": {
      return {
        ...state,
        error: action.error,
        uploading: false,
      };
    }
    default: {
      return state;
    }
  }
}

type TState = {
  error: string,
  message: string,
  uploading: boolean,
};

const Uploader: TUploader = ({ children }) => {
  const [state: TState, dispatch] = React.useReducer(uploaderReducer, {
    uploading: false,
    error: null,
  });

  const location = useLocation();

  const [uploadFile] = useMutation(UPLOAD_FILE, {});

  const [createMessage] = useMutation(CREATE_MESSAGES, {});

  const [clientMessage] = useMutation(CLIENT_MESSAGE, {});

  const searchParams = React.useMemo(() => {
    const search = location.search
      .slice(1)
      .split("&")
      .reduce((prev, current) => {
        const [k, v] = current.split("=");
        return Object.assign(prev, { [k]: v });
      }, {});

    return search;
  }, [location]);

  const mapToPromise = React.useCallback(
    (item) => {

      const fileName = item.data?.uploadFile?.filename || "Document";
      const message = item.data.uploadFile.url + ' ' + fileName;
      const type = message.split(".").pop() || "image";

      const { scid, platform, instance, instanceId } = searchParams;

      let input = {
        scid,
        source: "agent",
        content: { type: type, value: message },
        platform,
        instance,
      };

      console.log("createMessageInput->", input);

      const promises = [
        createMessage({
          variables: { input },
          context: {
            headers: {
              "x-tenant-id": instanceId,
            },
          },
        }),
        clientMessage({
          variables: {
            input: {
              scid,
              source: "client",
              platform,
              instance,
              content: { type: "text", value: "[agent]: " + message },
              attrs: {},
            },
          },
          context: {
            headers: {
              "x-tenant-id": instanceId,
            },
          },
        }),
      ];

      return Promise.all(promises);
    },
    [createMessage, searchParams, clientMessage]
  );

  const onUploadError = React.useCallback(
    (reasons) => {
      console.log("onDropAllError->", reasons);

      dispatch({
        type: "error",
        error: JSON.stringify(reasons),
      });
    },
    [dispatch]
  );

  const onUploadSuccess = React.useCallback(
    (response: []) => {
      console.log("onDropAllResponse->", response);

      const promises = response.map(mapToPromise);

      Promise.all(promises)
        .then((values) => {
          console.log("onCreateMessage->", values);

          dispatch({
            type: "done",
            message: "Message sent to the client",
          });

          //Testing?
        })
        .catch(onUploadError);
    },
    [mapToPromise, onUploadError]
  );

  const onUploadStart = (acceptedFiles) => {
    console.log("onDrop->", acceptedFiles);

    dispatch({
      type: "uploading",
    });
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      onUploadStart(acceptedFiles);
      console.log(acceptedFiles);
      const promises = acceptedFiles.map((file) =>
        uploadFile({
          variables: {
            file,
          },
        })
      );

      return Promise.all(promises).then(onUploadSuccess).catch(onUploadError);
    },
    [uploadFile, onUploadSuccess, onUploadError]
  );

  const loading = findChild(children, LoadingIndicator);
  const dropzone = findChild(children, DropZone);

  return (
    <UploaderContext.Provider
      value={{
        onDrop,
      }}
    >
      <div className="container">
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="jumbotron w-100">
            <div className="text-center">
              {state.uploading && loading}
              {!state.uploading && dropzone}
              {state.message && (
                <div className="alert alert-success" role="alert">
                  {state.message}
                </div>
              )}
              {state.error && (
                <div className="alert alert-danger" role="alert">
                  {state.error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </UploaderContext.Provider>
  );
};

Uploader.DropZone = DropZone;
Uploader.LoadingIndicator = LoadingIndicator;

export default Uploader;
