import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import "bootstrap/dist/css/bootstrap.min.css";
import Uploader from "./components/Uploader";
import { BrowserRouter } from "react-router-dom";
;

const createApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_API,
    }),
  });
};

const App = ({ apolloClient = createApolloClient() }) => {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <Uploader>
          <Uploader.DropZone />
          <Uploader.LoadingIndicator />
        </Uploader>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
