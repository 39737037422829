//@flow
import { CircularProgress } from "@material-ui/core";
import * as React from "react";

type Props = {
  children?: React.Node,
};
const LoadingIndicator = (props: Props) => <CircularProgress />;

export default LoadingIndicator;
